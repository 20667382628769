import React, { useState, useEffect } from 'react';
import { Divider, Spin,Typography, Row, Space, Col, Card,Layout,Dropdown,Button,Menu,Image} from 'antd';
import {HomeFilled, BellTwoTone,GlobalOutlined} from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import { useSelector,useDispatch } from 'react-redux';
import { I18n, setLocale} from 'react-redux-i18n';
import { updateForm } from './reducers/formSlice';

import 'antd/dist/antd.css';
import logo from './images/logo-food-angel.png';

import * as ToolRequest from './ToolRequest';

const {Text,Title} = Typography;
const { Content, Header, Footer } = Layout;
const colLayout = {
  xs: 24,
  sm: 24,
  md: 6,
  lg: 6,
  xl: 6
}

export default function App (props) {
  const [loaded, setLoaded] = useState(false);
  const [kiosks, setKiosk] = useState([]);
  const dispatch = useDispatch();
  const formData = useSelector(state => state.form.value);
  const locale = formData.locale


  useEffect(() => {
    (async () => {
      const kioskRes = await ToolRequest.request('GET', `/kiosks`);
      setKiosk(kioskRes)
      setLoaded(true)
    })();
  }, []);
  return loaded ? (
    <Layout>
      <Header style={{paddingLeft:30,paddingRight:30}}>
        <Row wrap={false} gutter={16} style={{height:'100%'}}>
          <Col style={{backgroundColor: 'white'}}>
            <Image preview={false} width={100} src={logo} style={{paddingLeft: 10,paddingRight: 10}} />
          </Col>
          <Col>
            {/*<Title style={{color: 'white'}} level={5}>{I18n.t('homePageHeaderTitle')}</Title>*/}
            <Row style={{height:'100%'}} align="middle">
              <Col><Title style={{color: 'white',marginBottom:0}} level={5}>{I18n.t('homePageHeaderTitle')}</Title></Col>
            </Row>
          </Col>
          <Col flex="auto">
            <Row justify="end">
              <Col>
                <div id="headerRightContainer">
                  <Dropdown overlay={
                    <Menu
                      items={[
                        {
                          label: '繁',
                          key: 'zh-tw',
                        },
                        {
                          label: '简',
                          key: 'zh-cn',
                        },
                        {
                          label: 'En',
                          key: 'en',
                        },
                      ]}
                      onClick={(item) => {
                        dispatch(setLocale(item.key));
                        dispatch(updateForm({
                          locale: item.key,
                        }));
                      }}
                    />
                  }>
                    <Button type="default" shape="circle" icon={<GlobalOutlined />} />
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <Content id="mainContainer">
        <Row gutter={3}>
          {kiosks.map((kiosk,j)=>{
            return (<Col key={j} {...colLayout} style={{marginTop:4}}>
              <Card
                size="small"
                title={kiosk.name[locale]}
                bodyStyle={{padding:10}}
              >
                <Row>
                  <Space>
                    <Col><HomeFilled/></Col>
                    <Col>{kiosk.address[locale]}</Col>
                  </Space>
                </Row>
                <Divider style={{marginTop:10,marginBottom:10}}/>
                <Row justify="end">
                  <Col style={{marginBottom:5}}>
                    <Text type={kiosk.state ? 'success':'danger'}>{kiosk.state ? I18n.t('homePageKioskStateInOperation') : I18n.t('homePageKioskStateInMaintenance')}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col flex="auto" style={{marginBottom:5}}>
                    <div id="productList">
                      {kiosk.products.map((product,i)=>{
                        return (<Row key={i} justify="space-between" style={{paddingTop:i===0?10:0,paddingBottom:i===kiosk.products.length - 1?10:0,paddingLeft:20,paddingRight:20}}>
                          <Col><Text strong>{product.title[locale]}</Text></Col>
                          <Col><Text strong>{product.amount}</Text></Col>
                          {i < kiosk.products.length - 1 ? <Divider style={{marginTop:10,marginBottom:10}}/> : null}
                        </Row>)
                      })}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>)
          })}
        </Row>
      </Content>
    </Layout>
  ) : <Spin style={{paddingTop:50,width: '100%'}} />;
};
