import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { i18nReducer, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import translations from '../locales';

import formReducer from './formSlice';

export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
    form: formReducer,
  },
  middleware: getDefaultMiddleware(),
});

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('zh-tw'));
syncTranslationWithStore(store)
