import axios from 'axios';

export const api = window.getConfig().apiHost;

export const request = async (method, uri, data = {}, config = {}) => {
    method = method.toUpperCase();
    return (() => {
        switch (method) {
            case 'GET':
            case 'DELETE':
                return axios[method.toLowerCase()](api + uri, {
                    params: data,
                    withCredentials: true,
                    ...config,
                });
            case 'POST':
            case 'PUT':
                return axios[method.toLowerCase()](api + uri, data, {
                    withCredentials: true,
                    ...config,
                });
        }
    })()
    .then(res => {
        console.log(`[Request] ${method} ${uri}`, res);
        return res.data;
    })
    .catch(e => {
        console.error(`[Request] ${method} ${uri}`, data, e.response);
        if (e.status === 403) {
        } else if (e.status !== 200) {
        }
        throw e;
    });
};
